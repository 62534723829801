// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyATbOtVBg5YmICJ9_lGoQmN4_WRhqQG6nU",
    authDomain: "webchatter-27fb8.firebaseapp.com",
    databaseURL: "https://webchatter-27fb8-default-rtdb.firebaseio.com",
    projectId: "webchatter-27fb8",
    storageBucket: "webchatter-27fb8.appspot.com",
    messagingSenderId: "519846812651",
    appId: "1:519846812651:web:8fced98d30f115b73cd7ae",
    measurementId: "G-671FX5ZQB9"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and export it
export const auth = getAuth(app);
export const db = getFirestore(app);

// Export the app if you need it elsewhere
export default app;
