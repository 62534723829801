// App.js
import React, { useState, useEffect } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import axios from 'axios';
import Sidebar from './Sidebar';
import ContentManager from './ContentManager';
import ChatInterface from './ChatInterface';
import './styles.css';
import LoginPage from './LoginPage';
import PopupChatbot from './PopupChatbot';
import { auth } from './firebase';
import ErrorBoundary from './ErrorBoundary';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Tracks whether the user has an active subscription
  const [isSubscribed, setIsSubscribed] = useState(false);

  // Folders & selection
  const [selectedFolder, setSelectedFolder] = useState({ name: 'Home', id: 'home' });
  const [folders, setFolders] = useState({});

  // Watch for user login state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (!currentUser) {
        // User signed out or never signed in
        setUser(null);
        setIsSubscribed(false);
        setLoading(false);
      } else {
        // User is logged in
        setUser(currentUser);

        // Now check subscription with our endpoint
        try {
          const res = await axios.post('/api/check-subscription', {
            email: currentUser.email,
          });
          console.log('[App.js] /api/check-subscription =>', res.data);
          setIsSubscribed(!!res.data.isSubscribed); 
        } catch (err) {
          console.error('[App.js] Subscription check failed:', err);
          setIsSubscribed(false);
        } finally {
          setLoading(false);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  // For debugging folders
  useEffect(() => {
    console.log('App component - Folders state:', folders);
  }, [folders]);

  // Logout handler
  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setIsSubscribed(false);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  // Example of dragging/dropping content
  const handleDropContent = async (folderId, content) => {
    if (!user) return;
    try {
      const idToken = await user.getIdToken();
      await axios.post('/moveContent', { folderId, contentId: content.id }, {
        headers: {
          Authorization: 'Bearer ' + idToken
        }
      });
      console.log('Content moved successfully:', content);
    } catch (error) {
      console.error('Error moving content:', error);
    }
  };

  // ---- RENDER LOGIC ----
  // 1) If we're still loading user/subscription info, show a loader
  if (loading) {
    return <div style={{ padding: '2rem' }}>Loading...</div>;
  }

  // 2) If no user is logged in, show the login page
  if (!user) {
    return <LoginPage />;
  }

  // 3) User is logged in but not subscribed => show subscription prompt
  if (!isSubscribed) {
    return (
      <div className="subscription-prompt">
        <div className="subscription-card">
          <h2>Get Started with Your Free Trial</h2>
          <p className="subtitle">Experience all features—completely free for 7 days.</p>
          <p className="subtitle">No payment info needed.</p>
          <div className="buy-button-container">
            <stripe-buy-button
              buy-button-id="buy_btn_1QlCHrL79rMHSHkCNlORqoCY"
              publishable-key="pk_test_51QRYyML79rMHSHkCmW3PlgfuNNMKVqYVoxCvaPpUXx3hQzp6yIXTQM5Swvlq74K401JCzQZGkzHm8vfX5KlUFApU00fp1BZAkR"
            ></stripe-buy-button>
          </div>
  
          <button className="logout-button" onClick={handleLogout}>
            Log out
          </button>
        </div>
      </div>
    );
  }
  

  // 4) Otherwise, user is logged in + subscribed => show main UI
  return (
    <ErrorBoundary>
      <div className="app-container app-main">
        <Sidebar
          onDropContent={handleDropContent}
          onSelectFolder={setSelectedFolder}
          selectedFolder={selectedFolder}
          folders={folders}
          setFolders={setFolders}
        />

        <div className="content-section">
          <div className="user-info d-flex align-items-center mb-4">
            {user.photoURL && (
              <img
                src={user.photoURL}
                alt="Profile"
                className="mr-4"
                width="40"
                height="40"
                style={{ borderRadius: '50%' }}
              />
            )}
            <div className="welcome-text">
              <h5 className="mb-0" style={{ fontSize: '16px' }}>
                Welcome {user.displayName?.split(' ')[0] || 'User'}!
              </h5>
            </div>

            <div className="action-buttons ml-auto">
              <button
                type="button"
                className="btn btn-outline-success"
                onClick={handleLogout}
              >
                Logout
              </button>
              <button
                className="btn btn-outline-danger"
                style={{ marginLeft: '5px' }}
                onClick={() =>
                  window.open(
                    'https://billing.stripe.com/p/login/test_6oE3g8d9KfPt9z23cc',
                    '_blank'
                  )
                }
              >
                Manage Subscription
              </button>
            </div>
          </div>

          <ChatInterface user={user} />
          <ContentManager
            selectedFolder={selectedFolder}
            folders={folders}
            setFolders={setFolders}
          />
          <PopupChatbot user={user} />
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default App;
