// ContentManager.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';
import { auth } from './firebase';

function ContentManager({ selectedFolder, folders, setFolders }) {
  const [contents, setContents] = useState([]);
  // const [titles, setTitles] = useState({});
  const [newContent, setNewContent] = useState('');
  const [expandedContent, setExpandedContent] = useState([]);
  const [selectedContents, setSelectedContents] = useState([]);
  const [editingTitleIndex, setEditingTitleIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentToMove, setContentToMove] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedFolderId, setSelectedFolderId] = useState(selectedFolder?.id || null);

  useEffect(() => {
    if (selectedFolder) {
      setSelectedFolderId(selectedFolder.id);
    }
  }, [selectedFolder]);

  useEffect(() => {
    if (selectedFolderId) {
      if (selectedFolderId === 'home' || selectedFolderId === null) {
        fetchContent();
      } else {
        fetchFolderContent(selectedFolderId);
      }
    } else {
      fetchContent();
    }
  }, [selectedFolderId]);

  // const fetchContent = async () => {
  //   try {
  //     const user = auth.currentUser;
  //     if (!user) return;
  //     const idToken = await user.getIdToken();

  //     const response = await axios.get('/getAllContent', {
  //       headers: {
  //         'Authorization': 'Bearer ' + idToken
  //       }
  //     });

  //     const contentsData = response.data.contents || {};
  //     const generalContents = Object.values(contentsData.general || {});
  //     setContents(generalContents);
  //   } catch (error) {
  //     console.error('Error fetching content:', error);
  //     setContents([]); // Ensure contents is always an array
  //   }
  // };


  const fetchContent = async () => {
    try {
      const user = auth.currentUser;
      if (!user) return;
      const idToken = await user.getIdToken();
  
      const response = await axios.get('/getAllContent', {
        headers: {
          'Authorization': 'Bearer ' + idToken
        }
      });
  
      const contentsData = response.data.contents || {};
      const generalContents = Object.values(contentsData.general || {});
      
      const unified = generalContents.map(c => ({
        id: c.id,
        content: c.content,
        title: c.title && typeof c.title === "string" ? c.title : "Untitled",
        createdAt: c.createdAt
      }));
      
      console.log('Fetched contents with titles:', unified);
      setContents(unified);
    } catch (error) {
      console.error('Error fetching content:', error);
      setContents([]); // Ensure contents is always an array
    }
  };

  // const fetchFolderContent = async (folderId) => {
  //   try {
  //     const user = auth.currentUser;
  //     if (!user) return;
  //     const idToken = await user.getIdToken();

  //     const response = await axios.get(`/getFolderContent/${folderId}`, {
  //       headers: {
  //         'Authorization': 'Bearer ' + idToken
  //       }
  //     });

  //     const folderContents = Object.values(response.data.contents || {});
  //     setContents(folderContents);
  //   } catch (error) {
  //     console.error('Error fetching folder content:', error);
  //     setContents([]); // Ensure contents is always an array
  //   }
  // };


  const fetchFolderContent = async (folderId) => {
    try {
      const user = auth.currentUser;
      if (!user) return;
      const idToken = await user.getIdToken();
  
      const response = await axios.get(`/getFolderContent/${folderId}`, {
        headers: {
          'Authorization': 'Bearer ' + idToken
        }
      });
  
      const folderContents = Object.values(response.data.contents || {});
      const unified = folderContents.map(c => ({
        id: c.id,
        content: c.content,
        title: c.title && typeof c.title === "string" ? c.title : "Untitled",
        createdAt: c.createdAt
      }));
      
      console.log('Fetched folder contents with titles:', unified);
      setContents(unified);
    } catch (error) {
      console.error('Error fetching folder content:', error);
      setContents([]); // Ensure contents is always an array
    }
  };

  const fetchFolders = async () => {
    try {
      const user = auth.currentUser;
      if (!user) return;
      const idToken = await user.getIdToken();

      const response = await axios.get('/getFolders', {
        headers: {
          'Authorization': 'Bearer ' + idToken
        }
      });
      setFolders(response.data || {});
    } catch (error) {
      console.error('Error fetching folders:', error);
    }
  };

  const addContent = async () => {
    if (!newContent.trim()) {
      console.log('No content to add');
      return;
    }

    try {
      const user = auth.currentUser;
      if (!user) return;
      const idToken = await user.getIdToken();

      // Use a default folder ID if selectedFolderId is undefined
      const folderId = (selectedFolderId === 'home' || !selectedFolderId) ? null : selectedFolderId;

      const response = await axios.post('/storeContent', {
        content: newContent,
        title: "Untitled", // pass the initial title explicitly
        folderId: folderId
      }, {
        headers: {
          'Authorization': 'Bearer ' + idToken
        }
      });

      const addedContent = response.data;

      if (!addedContent || !addedContent.id) {
        console.error('Invalid response from server:', addedContent);
        setSuccessMessage('Error adding content. Please try again.');
        return;
      }

      // Update local state
      setContents(prev => [...prev, {
        id: addedContent.id,
        content: addedContent.content,
        title: addedContent.title || "Untitled"
    }]);
    


      setNewContent('');
      setSuccessMessage('Content added successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error('Error adding content:', error);
      setSuccessMessage('Error adding content. Please try again.');
      setTimeout(() => setSuccessMessage(''), 3000);
    }
  };

  const deleteSelected = async () => {
    if (window.confirm('Are you sure you want to delete the selected items?')) {
      for (const content of selectedContents) {
        try {
          const user = auth.currentUser;
          if (!user) return;
          const idToken = await user.getIdToken();

          await axios.post('/deleteContent', { contentId: content.id }, {
            headers: {
              'Authorization': 'Bearer ' + idToken
            }
          });

          setContents(prevContents => prevContents.filter(c => c.id !== content.id));
        } catch (error) {
          console.error('Error deleting content:', error);
        }
      }
      setSelectedContents([]);
    }
  };

  const handleCheckboxChange = (content) => {
    setSelectedContents(prev =>
      prev.some(item => item.id === content.id)
        ? prev.filter(item => item.id !== content.id)
        : [...prev, content]
    );
  };

  const toggleExpand = (id) => {
    setExpandedContent(prev =>
      prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]
    );
  };

  const handleTitleDoubleClick = (id) => {
    setEditingTitleIndex(id);
  };

  const handleTitleChange = (id, newTitle) => {
    setContents(prev =>
      prev.map(c =>
        c.id === id ? { ...c, title: newTitle } : c
      )
    );
  };
  

  const handleTitleBlur = () => {
    setEditingTitleIndex(null);
  };

  // const saveTitle = async (contentId) => {
  //   const newTitle = titles[contentId];
  //   try {
  //     // Implement save title functionality here
  //     setEditingTitleIndex(null);
  //   } catch (error) {
  //     console.error('Error updating title:', error);
  //   }
  // };

  const saveTitle = async (contentId) => {
    const contentObj = contents.find(c => c.id === contentId);
    const newTitle = contentObj?.title || "Untitled";
    console.log('Saving Title to server:', newTitle);
    
    try {
      const user = auth.currentUser;
      if (!user) return;
      const idToken = await user.getIdToken();
  
      const response = await axios.post('/updateTitle', {
        contentId,
        title: newTitle
      }, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
  
      console.log('Server response:', response.data);
      
      // Update local state to ensure title is saved
      setContents(prev => prev.map(c => 
        c.id === contentId ? {...c, title: newTitle} : c
      ));
      
      setEditingTitleIndex(null);
      setSuccessMessage('Title updated successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error('Error updating title:', error);
      setSuccessMessage('Error updating title. Please try again.');
      setTimeout(() => setSuccessMessage(''), 3000);
    }
  };
  


  const handleDragStart = (event, content) => {
    event.dataTransfer.setData('text/plain', JSON.stringify(content));
    event.dataTransfer.effectAllowed = 'move';
    event.currentTarget.style.opacity = '0.5';
  };

  const handleDragEnd = (event) => {
    event.currentTarget.style.opacity = '1';
  };

  const deleteContent = async (contentId) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      try {
        const user = auth.currentUser;
        if (!user) return;
        const idToken = await user.getIdToken();

        await axios.post('/deleteContent',
          { contentId },
          { headers: { 'Authorization': `Bearer ${idToken}` } }
        );

        console.log('Content deleted successfully');
      } catch (error) {
        console.error('Error deleting content:', error.response ? error.response.data : error.message);
        alert('Failed to delete content. Please try again.');
      }
    }
  };

  const openMoveModal = (content) => {
    setContentToMove(content);
    setIsModalOpen(true);
    fetchFolders();
  };

  const closeMoveModal = () => {
    setContentToMove(null);
    setIsModalOpen(false);
  };

  const handleMoveToFolder = async (folder) => {
    if (!folder) return;

    console.log('Moving content to folder:', folder.name);
    console.log('Content being moved:', contentToMove);

    try {
      const user = auth.currentUser;
      if (!user) {
        console.error('No user logged in');
        alert('You must be logged in to move content.');
        return;
      }

      const idToken = await user.getIdToken();

      const response = await axios.post('/moveContent',
        {
          folderId: folder.id,
          contentId: contentToMove.id
        },
        {
          headers: {
            'Authorization': 'Bearer ' + idToken
          }
        }
      );

      console.log('Server response:', response.data);

      if (response.data.message === 'Content moved successfully') {
        // Update local state
        setContents(prev => prev.filter(c => c.id !== contentToMove.id));
        closeMoveModal();
        alert('Content moved successfully!');
      } else {
        throw new Error('Unexpected server response');
      }

    } catch (error) {
      console.error('Error moving content:', error);
      if (error.response) {
        console.error('Server error response:', error.response.data);
        alert(`Failed to move content: ${error.response.data.error}`);
      } else if (error.request) {
        console.error('No response received:', error.request);
        alert('Failed to move content: No response from server');
      } else {
        console.error('Error details:', error.message);
        alert(`Failed to move content: ${error.message}`);
      }
    }
  };

  const renderFolderOptions = (foldersList = folders) => {
    if (!foldersList || Object.keys(foldersList).length === 0) {
      return <div>No folders available</div>;
    }

    return Object.values(foldersList).map(folder => (
      <div key={folder.id} style={{ paddingLeft: '20px' }}>
        <button
          onClick={() => {
            console.log('Folder clicked:', folder.name);
            handleMoveToFolder(folder);
          }}
          className="folder-option-button"
        >
          {folder.name}
        </button>
        {folder.subfolders && Object.keys(folder.subfolders).length > 0 && (
          <div style={{ marginLeft: '20px' }}>
            {renderFolderOptions(folder.subfolders)}
          </div>
        )}
      </div>
    ));
  };

  const renderContent = () => {
    // 1) Slice the data based on currentPage and itemsPerPage
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    let contentsToRender = contents.slice(startIndex, endIndex);
  
    // 2) Ensure contentsToRender is at least an empty array
    if (!Array.isArray(contentsToRender)) {
      console.error('contentsToRender is not an array:', contentsToRender);
      contentsToRender = [];
    }
  
    // 3) Show "No content available" if empty
    if (contentsToRender.length === 0) {
      return (
        <tr>
          <td colSpan="2" style={{ textAlign: 'center' }}>
            No content available.
          </td>
        </tr>
      );
    }
  
    // 4) Now map over only this sliced subset of contents
    return contentsToRender.map((content, index) => {
      if (!content || typeof content !== 'object') {
        console.error('Invalid content item:', content);
        return null;
      }

      const contentText = typeof content.content === 'string' ? content.content : JSON.stringify(content);
      const truncatedContent = contentText.split(" ").slice(0, 100).join(" ");
      
      // Convert createdAt to a human‐readable string
      // const createdDate = content.createdAt ? new Date(content.createdAt).toLocaleString() : null;
      const dateOptions = { 
        month: 'long', 
        day: 'numeric', 
        year: 'numeric', 
        hour: '2-digit', 
        minute: '2-digit', 
        hour12: true 
        };
        const createdDate = content.createdAt 
          ? new Date(content.createdAt).toLocaleString('en-US', dateOptions) 
          : null;
  
      return (
        <tr key={content.id || index} draggable onDragStart={(e) => handleDragStart(e, content)} onDragEnd={handleDragEnd}>
          <td>
            <input
              type="checkbox"
              onChange={() => handleCheckboxChange(content)}
              checked={selectedContents.some(item => item.id === content.id)}
            />
          </td>
          <td>
            <div className="title-row" onDoubleClick={() => handleTitleDoubleClick(content.id)}>
              {editingTitleIndex === content.id ? (
                <>
                  <input
                    type="text"
                    value={content.title}
                    onChange={(e) => handleTitleChange(content.id, e.target.value)}
                    style={{ backgroundColor: 'lightblue' }}
                    onBlur={() => {
                    saveTitle(content.id);    // <--- AUTO-SAVE
                    setEditingTitleIndex(null);
                    }}
                  />
                </>
              ) : (
                // <>
                // <span>{content.title}</span>
                // {createdDate && (
                //   <span style={{ 
                //   marginLeft: '8px', 
                //   fontSize: '0.75rem', 
                //   color: 'gray'
                //   }}>
                //   {createdDate}
                //   </span>
                //   )}
                // </>
                <div style={{
                   display: 'flex',
                   justifyContent: 'space-between',
                   alignItems: 'center',
                   width: '100%'
                 }}>
                   <span>{content.title}</span>
                   {createdDate && (
                     <span style={{ fontSize: '0.75rem', color: 'gray' }}>
                       {createdDate}
                     </span>
                   )}
                 </div>
              )}
            </div>
            <div className="content-row">
              {expandedContent.includes(content.id) ? contentText : truncatedContent + '... '}
              <a href="#" onClick={(e) => { e.preventDefault(); toggleExpand(content.id); }}>
                {expandedContent.includes(content.id) ? 'See Less' : 'See More'}
              </a>
              {' | '}
              <a href="#" onClick={(e) => { e.preventDefault(); deleteContent(content.id); }} style={{ color: 'red' }}>
                Delete
              </a>
              {' | '}
              <a href="#" onClick={(e) => { e.preventDefault(); openMoveModal(content); }} style={{ color: 'blue' }}>
                Move to Folder
              </a>
            </div>
          </td>
        </tr>
      );
    }).filter(Boolean); // Remove null entries
  };

  const renderPageNumbers = () => {
    const totalPages = Math.ceil(contents.length / itemsPerPage);
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-number ${currentPage === i ? 'active' : ''}`}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </li>
      );
    }
    return <ul className="pagination">{pageNumbers}</ul>;
  };

  return (
    <div>
      <h2 style={{ fontSize: '20px', marginTop: '20px' }}>
        {selectedFolder ? (selectedFolder.id === 'home' ? 'Home' : selectedFolder.name) : 'Uncategorized'}
      </h2>
      <table className="content-table">
        <thead>
          <tr>
            <th>Select</th>
            <th>Content</th>
          </tr>
        </thead>
        <tbody>
          {renderContent()}
        </tbody>
      </table>
      {renderPageNumbers()}
      <button className="delete-button" onClick={deleteSelected} disabled={selectedContents.length === 0}>
        Delete Selected
      </button>
      <h3 style={{ fontSize: '20px', marginTop: '20px' }}>Add Content:</h3>
      {successMessage && (
        <div className="success-message" style={{ color: 'green', marginBottom: '10px' }}>
          {successMessage}
        </div>
      )}
      <textarea value={newContent} onChange={(e) => setNewContent(e.target.value)} placeholder="Add your content ..." />
      <button onClick={addContent} style={{ marginBottom: '20px', borderRadius: '6px' }}>Add Content</button>
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeMoveModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>Select a folder to move the content</h3>
            <div className="folder-options">
              {renderFolderOptions()}
            </div>
            <button onClick={closeMoveModal} className="close-modal-button">Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContentManager;
