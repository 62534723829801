import React, { useState, useEffect } from 'react';
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import './LoginPage.css';
import WebChaterLogo from './WebChatterLogo2.png';
import { auth } from './firebase';
import axios from 'axios';

function LoginPage({ user, logoSize = 200 }) {
  // Track errors (e.g., sign-in or subscription check)
  const [errorMessage, setErrorMessage] = useState('');
  
  // Have we finished checking subscription yet?
  const [subscriptionChecked, setSubscriptionChecked] = useState(false);
  
  // Is user subscribed?
  const [isSubscribed, setIsSubscribed] = useState(false);

  // ----- STEP 1: Google Sign-In -----
  const handleGoogleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log('User signed in:', result.user);
        // The "user" prop will eventually update in the parent component 
        // (assuming you pass it down from an Auth state / onAuthStateChanged).
      })
      .catch((error) => {
        console.error('Error during sign in:', error.code, error.message);
        setErrorMessage(error.message);
      });
  };

  // ----- STEP 2: Once we have user.email, check subscription via /api/check-subscription -----
  useEffect(() => {
    // If the user isn't logged in or we don't have an email, we can't check
    if (!user || !user.email) {
      return;
    }

    (async () => {
      try {
        console.log('[LoginPage] Checking subscription for:', user.email);
        setSubscriptionChecked(false);

        // Call your server route that checks Stripe by email
        const response = await axios.post('/api/check-subscription', {
          email: user.email
        });
        
        // IMPORTANT: See what the server actually returns
        console.log('[LoginPage] /api/check-subscription response:', response.data);

        // If response.data.isSubscribed is truthy, user is subscribed
        setIsSubscribed(!!response.data.isSubscribed);
      } catch (err) {
        console.error('[LoginPage] Subscription check failed:', err);
        setErrorMessage('Failed to check subscription status.');
      } finally {
        setSubscriptionChecked(true);
      }
    })();
  }, [user]);

// ----- RENDER LOGIC -----
// (A) User not logged in => show Google sign-in
if (!user) {
  return (
    <div className="login-page">
      <div className="login-card"> {/* ✅ Switched to .login-card for styling */}
        
        <img 
          src={WebChaterLogo} 
          alt="WebChater Logo" 
          className="login-logo" 
          style={{ width: `${logoSize}px` }}
        />
        
        {/* 🔵 Modern heading styling */}
        <h1 className="login-title">Welcome to WebChatter App</h1>
        
        {/* 🔵 Subtitle */}
        <p className="login-subtitle">Your AI companion for chatting with web content.</p>

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        {/* 🔵 Google sign-in button remains the same */}
        <button onClick={handleGoogleSignIn} className="google-sign-in-button">
          <img 
            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" 
            alt="Google logo" 
          />
          Sign in with Google
        </button>

        {/* Optional note */}
        <p className="note">Secure and instant access</p>

      </div>
    </div>
  );
}


  // (B) User is logged in but we haven't finished checking subscription => loading
  if (!subscriptionChecked) {
    return (
      <div className="login-page">
        <div className="login-container">
          <p>Checking subscription status...</p>
        </div>
      </div>
    );
  }

  // (C) Subscription checked, user is NOT subscribed => show subscription prompt
  if (!isSubscribed) {
    return (
      <div className="login-page">
        <div className="login-container">
          <img 
            src={WebChaterLogo} 
            alt="WebChater Logo" 
            className="login-logo" 
            style={{ width: `${logoSize}px` }}
          />
          <h1>Subscribe to Access WebChatter App</h1>
          <p>Please complete your subscription below</p>

          {/* Stripe Buy Button */}
          <div>
            <stripe-buy-button
              buy-button-id="buy_btn_1QlCHrL79rMHSHkCNlORqoCY"
              publishable-key="pk_test_51QRYyML79rMHSHkCmW3PlgfuNNMKVqYVoxCvaPpUXx3hQzp6yIXTQM5Swvlq74K401JCzQZGkzHm8vfX5KlUFApU00fp1BZAkR"
            ></stripe-buy-button>
          </div>
        </div>
      </div>
    );
  }

  // (D) Subscription checked + user is subscribed => show main UI
  return (
    <div className="login-page">
      <div className="login-card">
        <img
          src="/path-to-your-logo.png"
          alt="WebChatter logo"
          className="login-logo"
        />
        <h1 className="login-title">Welcome to WebChatter App</h1>
        <p className="login-subtitle">Your AI companion for chatting with web content.</p>

        {errorMessage && <div className="error-message">{errorMessage}</div>}

        <button className="google-sign-in-button" onClick={handleGoogleSignIn}>
          <img src="/google-icon.svg" alt="Google icon" />
          Sign in with Google
        </button>
      </div>
    </div>
  );
}

export default LoginPage;
